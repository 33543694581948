import { render, staticRenderFns } from "./comp_MagicButton.vue?vue&type=template&id=04baeaf8"
import script from "./comp_MagicButton.vue?vue&type=script&lang=js"
export * from "./comp_MagicButton.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports